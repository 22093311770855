/*
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          DevOps' REACT Hello World sourced from: <code>src/App.js</code>.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/

import React, { Component } from 'react';
import logo from './logo.svg';
import './App2.css';

const tileList = [
  {url: 'https://www.estopwatch.net/',                    alt: 'Stopwatch',                  pic: ''},
  {url: 'https://www.rapidtables.com/tools/notepad.html', alt: 'Unsecure Notepad',           pic: ''},
  {url: 'https://www.editpad.org/',                       alt: 'Unsecure Editpad',           pic: ''},
  {url: 'https://www.protectedtext.com/sillycrew',        alt: 'Secure Notepad',             pic: ''},
  {url: 'http://www.google.com',                          alt: 'Google',                     pic: 'home/icons/google.png'},
  {url: 'https://www.estopwatch.net/countdown.html',      alt: 'Timer',                      pic: ''},
  {url: 'https://www.mathsisfun.com/games/connect4.html', alt: '4inRow',                     pic: ''},
  {url: 'https://cardgames.io/checkers/',                 alt: 'Checkers',                   pic: ''},
  {url: 'https://buddyboardgames.com/farkle',             alt: 'Farkle',                     pic: ''},
  {url: 'https://buddyboardgames.com/',                   alt: 'Buddy Board Games',          pic: ''},
  {url: 'https://cardgames.io/',                          alt: 'Card games.io',              pic: ''},
  {url: 'http://en.battleship-game.org',                  alt: 'Battleship',                 pic: 'home/icons/battleship.jpg'},
  {url: 'http://www.coursera.com/?authMode=login',        alt: 'Coursera',                   pic: 'home/icons/coursera.jpg'},
  {url: 'https://mail.yahoo.com/',                        alt: 'Yahoo Mail',                 pic: 'home/icons/yahoomail.jpg'},
  {url: 'https://www.outlook.com/',                       alt: 'Outlook',                    pic: 'home/icons/outlook.png'},
  {url: 'https://console.aws.amazon.com/console/home',    alt: 'AWS',                        pic: 'home/icons/aws.jpg'},
  {url: 'https://www.amazon.com',                         alt: 'Amazon',                     pic: 'home/icons/amazon.png'},
  {url: 'https://news.google.com',                        alt: 'Google News',                pic: 'home/icons/googlenews.jpg'},
  {url: 'https://www.npr.org',                            alt: 'NPR',                        pic: 'home/icons/npr.jpg'},
  {url: 'https://www.bbc.com',                            alt: 'BBC',                        pic: 'home/icons/bbc.png'},
  {url: 'https://happynews.com/',                         alt: 'Happy News',                 pic: ''},
  {url: 'https://newsforkids.net/',                       alt: 'News For Kids',              pic: ''},
  {url: 'https://www.tweentribune.com/',                  alt: 'Tween Tribune',              pic: ''},
  {url: 'https://www.cnn.com/cnn10',                      alt: 'CNN 10',                     pic: ''},
  {url: 'https://kids.nationalgeographic.com/',           alt: 'National Geographic Kids',   pic: ''},
  {url: 'https://www.dogonews.com/',                      alt: 'DOGO News',                  pic: ''},
  {url: 'https://www.sciencenewsforstudents.org/',        alt: 'Science News for Students',  pic: ''},
  {url: 'https://apnews.com/',                            alt: 'AP News',                    pic: 'home/icons/apnews.jpg'},
  {url: 'https://www.csmonitor.com/',                     alt: 'Christian Science Monitor',  pic: ''},
  {url: 'https://www.c-span.org/',                        alt: 'C-SPAN',                     pic: ''},  
  {url: 'https://www.reuters.com/',                       alt: 'Reuters',                    pic: ''},  
  {url: 'https://www.cnet.com',                           alt: 'CNET',                       pic: 'home/icons/cnet.jpg'},  
  {url: 'https://www.theverge.com',                       alt: 'The Verge',                  pic: 'home/icons/theverge.png'},
  {url: 'https://arstechnica.com/',                       alt: 'Ars Technica',               pic: 'home/icons/ars.png'},
  {url: 'https://mspoweruser.com/',                       alt: 'Microsoft Power User',       pic: 'home/icons/mspu.png'},
  {url: 'https://www.technologyreview.com/',              alt: 'MIT Review',                 pic: 'home/icons/mit.png'},
  {url: 'https://www.bgr.com',                            alt: 'BGR',                        pic: 'home/icons/bgr.jpg'},
  {url: 'https://www.wikipedia.com',                      alt: 'Wikipedia',                  pic: 'home/icons/wikipedia.jpg'},
  {url: 'https://www.yahoo.com',                          alt: 'Yahoo News',                 pic: 'home/icons/yahoonews.jpg'},
  {url: 'http://www.bing.com',                            alt: 'Bing',                       pic: 'home/icons/bing.jpg'},
  {url: 'http://www.dogpile.com',                         alt: 'Dogpile',                    pic: 'home/icons/dogpile.png'},
  {url: 'https://www.worldometers.info/',                 alt: 'World-o-meters',             pic: 'home/icons/worldometers.jpg'},
  {url: 'https://phys.org',                               alt: 'Phys.org',                   pic: 'home/icons/phys.org.jpg'},
  {url: 'https://www.sciencedaily.com/',                  alt: 'Science Daily',              pic: 'home/icons/sciencedaily.png'}  
];

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
        </header>
		{tileList.map(function(item) {
		  //return <div>{item.url}</div>
		  return <a href={item.url} onselect="clearSelect()"><span name="" class="icon"><br / >{item.alt}</span></a>		  
		})}
		</div>
    );
  }
}

export default App;


var g_title = "sillycrew.com";
var g_ver = g_title + " 5dec2020 @ 022:46 US CST";
var g_consoleHideCustomFooter = false;
var g_consoleDisableCustomLog = false; 
window.document.title=g_title; 

function clearSelect() {
  if (window.getSelection) {window.getSelection().removeAllRanges();}
  else if (document.selection) {document.selection.empty();}
}

function gText(e) {
  var t = (document.all) ? document.selection.createRange().text : document.getSelection();
  console.log(t);  
  clearSelect();
}

/*
function displayIcon(url, alt, pic) {
  document.write('<a href="'+url+'" onselect="clearSelect()"><span name="" class="icon">'+alt+'<!--<img alt="'+alt+'" src="'+pic+'" class="edge" style="object-fit:contain; width:100%;" / >--></span></a>');
}
*/

document.onmouseup = gText;
if (!document.all) document.captureEvents(Event.MOUSEUP);
